import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const Contact = () => {
  const lang = "de";

  return (
    <Layout
      seoTitle="Kontakt bestätigung"
      lang={lang}
      translationPL="/kontakt/"
      translationEN="/en/contact-us/"
    >
      <SmallBanner title="Kontakt bestätigung" lang={lang} />
      <section className="contact-main">
        <div className="text-center">
          <p>Vielen Dank für das Senden einer Nachricht!</p>
          <Link className="btn" to="/de/" data-hover="Startseite">
            Startseite
            <span className="btn-arrows"></span>
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
